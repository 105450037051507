import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import type React from "react";
import { FormattedMessage } from "react-intl";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import { MetadataList } from "@metronome/features/MetadataList";
import type { IBusinessDimensionNode } from "@metronome/types/BusinessDimension";
import type { IStepInstance } from "@metronome/types/StepInstance";
import styles from "./summary.module.scss";
import { InfoCircledIcon } from "@radix-ui/react-icons";
import { StepSummaryInfo } from "./StepSummaryInfo";

const StepInstanceInfo = (): JSX.Element => (
	<div>
		<strong>
			<FormattedMessage id="STEP_INSTANCE.SUMMARY" />
		</strong>
		<ul>
			<li>
				<strong>
					<FormattedMessage id="STEP_INSTANCE.TYPE" />
				</strong>
				: <FormattedMessage id="TYPE_DEFINITION" />
			</li>
			<li>
				<strong>
					<FormattedMessage id="STEP_INSTANCE.DURATION" />
				</strong>
				: <FormattedMessage id="DURATION_DEFINITION" />
			</li>
			<li>
				<strong>
					<FormattedMessage id="DUE_DATE" />
				</strong>
				: <FormattedMessage id="DUE_DATE_DEFINITION" />
			</li>
			<li>
				<strong>
					<FormattedMessage id="DEADLINE" />
				</strong>
				: <FormattedMessage id="DEADLINE_DEFINITION" />
			</li>
			<li>
				<strong>
					<FormattedMessage id="STEP_INSTANCE.EFFECTIVE_DATE" />
				</strong>
				: <FormattedMessage id="EFFECTIVE_DATE_DEFINITION" />
			</li>
		</ul>

		<strong>
			<FormattedMessage id="ROLES_AND_RESPONSIBILITIES" />
		</strong>

		<ul>
			<li>
				<strong>
					<FormattedMessage id="ASSIGNEE" />:
				</strong>{" "}
				<FormattedMessage id="RESPONSIBLE_DEFINITION" />
			</li>
			<li>
				<strong>
					<FormattedMessage id="ACCOUNTABLE" />:
				</strong>{" "}
				<FormattedMessage id="ACCOUNTABLE_DEFINITION" />:
			</li>
			<li>
				<strong>
					<FormattedMessage id="CONSULTED" />:
				</strong>{" "}
				<FormattedMessage id="CONSULTED_DEFINITION" />:
			</li>
			<li>
				<strong>
					<FormattedMessage id="INFORMED" />:
				</strong>{" "}
				<FormattedMessage id="INFORMED_DEFINITION" />
			</li>
		</ul>
	</div>
);

type Props = {
	stepInstance: IStepInstance;
	isSideOpen?: boolean;
	showSidePanel: boolean;
	setShowSidePanel: (showSidePanel: boolean) => void;
	businessDimension: IBusinessDimensionNode;
};
export const StepSummary: React.FC<Props> = ({
	stepInstance,
	showSidePanel,
	setShowSidePanel,
	businessDimension,
}) => {
	return (
		<div className={styles.container}>
			<div className="flex flex-row items-center gap-2">
				<button
					type="button"
					onClick={() => setShowSidePanel(!showSidePanel)}
					className={clsx({
						[styles.actionTriggerSidePanel]: true,
						[styles.actionTriggerActive]: showSidePanel,
					})}
				>
					<FontAwesomeIcon icon={["fas", "arrow-right-from-bracket"]} />
				</button>
				<strong>
					<FormattedMessage id="OTHER_INFORMATIONS" />
				</strong>
			</div>
			<div className="p-2 bg-grey relative group">
				<Dialog>
					<DialogTrigger className="absolute top-0 end-0 p-1.5 rounded hover:bg-slate-200 transition-colors">
						<InfoCircledIcon className="text-slate-600 transition-opacity opacity-0 group-hover:opacity-100" />
					</DialogTrigger>
					<DialogContent>
						<StepInstanceInfo />
					</DialogContent>
				</Dialog>
				<StepSummaryInfo stepInstance={stepInstance} />
				<div className="mt-4" />
			</div>
			{!!businessDimension && (
				<div className="p-2 bg-grey">
					<MetadataList
						contextId={stepInstance.id}
						context="step-instances"
						businessDimension={businessDimension}
					/>
				</div>
			)}
		</div>
	);
};
