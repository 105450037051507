import * as duration from "duration-fns";
import type { Mode } from "@nessprim/planby-pro/dist/Epg/helpers";
import { format, roundToNearestHours } from "date-fns";

export const getStartDate = (startDate: string): string => {
	let date: undefined | Date;
	try {
		date = new Date(startDate);
	} catch {
		throw new Error("Could not parse the date");
	}
	return format(
		roundToNearestHours(date, { roundingMethod: "floor" }),
		"yyyy-MM-dd'T'HH:mm:ss",
	);
};

export const getDefaultTimeRange = (
	first: string,
	last: string,
): Mode["type"] => {
	const durationBetween = duration.between(first, last);
	const normalizedDuration = duration.normalize(durationBetween);
	const numberofDays = duration.toDays(normalizedDuration);

	if (numberofDays > 1) {
		return "week";
	}
	return "day";
};

export const getDayWidth = (
	mode: Mode["type"],
	numberOfDays: number,
): number => {
	if (mode === "day" && numberOfDays >= 1) return 1800 * numberOfDays;
	if (mode === "week") return 14400;
	if (mode === "month") return 1800;
	return 1800;
};

export const getAvailableRange = (
	defaultRange: Mode["type"],
): Mode["type"][] => {
	if (defaultRange === "day") return ["day"];
	if (defaultRange === "week") return ["day", "week"];
	return ["day", "week", "month"];
};

export const durationInDays = (dateRight: string, dateLeft: string) => {
	const durationRaw = duration.between(dateRight, dateLeft);
	return duration.toDays(durationRaw);
};

export const globalStyles = `
.planby {
  .planby-program-content {
    background: none;
  }
  #planby-wrapper {
  border-radius: 0px;
  }
  .planby-content {
  --off-white-color: white;
    background-image: linear-gradient(#eee 1.1px, transparent 1.1px),
      linear-gradient(to right, #eee 1.1px, var(--off-white-color) 1.1px);
    background-size: 22px 22px;
    background-color: rgb(241, 235, 226);
    background-repeat: repeat;
  }
  .planby-program {
    z-index: 10;
  }
}
`;
