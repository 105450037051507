import {
	singleProcessStreamsOptions,
	useProcessStreamResourceAllocationDefinitions,
} from "@metronome/api/useProcessStreamInstances";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Link, useNavigate } from "@tanstack/react-router";
import * as v from "valibot";
import { ProcessStreamSummary } from "@metronome/features/ProcessStream/Summary";
import { useClusters } from "@metronome/api/useClusters";
import clsx from "clsx";
import { Badge } from "@metronome/components/ui/badge";
import type React from "react";
import { useCallback, useEffect, useMemo, useState, type FC } from "react";
import type { IProcessStream } from "@metronome/types/ProcessStream";
import { useStepInstancesFromCluster } from "@metronome/api/useStepInstance";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import type { ClusteredStepInstances } from "@metronome/types/StepInstance";
import type { ResourceAllocation } from "@metronome/types/Resources";
import type { Channel, Program } from "@nessprim/planby-pro/dist/Epg/helpers";
import {
	ChannelBox,
	type ChannelItem,
	Epg,
	Layout,
	useEpg,
} from "@nessprim/planby-pro";
import { endOfDay, format } from "date-fns";
import { planbyLightTheme } from "@metronome/constants/planbyTheme";
import {
	durationInDays,
	getDayWidth,
	getDefaultTimeRange,
	getStartDate,
} from "@metronome/utils/planby";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@metronome/components/ui/select";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@metronome/components/ui/collapsible";

import {
	ActivityLogIcon,
	ArrowRightIcon,
	CalendarIcon,
	CheckCircledIcon,
	ChevronRightIcon,
	CircleIcon,
} from "@radix-ui/react-icons";
import { FormattedMessage } from "react-intl";
import { CustomItem } from "@metronome/features/planby/CustomItem";
import { Slider } from "@metronome/components/ui/slider";
import { CustomItemSchedule } from "@metronome/features/UpdateStepInstanceSchedule";
import { AvatarsList } from "@metronome/components/AvatarsList";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@metronome/components/ui/tabs";
import useMetadataDefinitions from "@metronome/api/useMetadataDefinitions";

const CustomChannelItem = ({ channel }: ChannelItem) => {
	const { position, title, treeName } = channel;
	return (
		<ChannelBox className="p-1 border-t z-50 gap-2 items-start" {...position}>
			<span className="text-slate-400">
				{treeName}
				<ArrowRightIcon className="inline" />
			</span>
			{title}
		</ChannelBox>
	);
};

type StepInstanceFromClusterProps = {
	clusteredStepInstances: ClusteredStepInstances;
	children?: React.ReactNode;
};
const StepInstanceFromCluster: FC<StepInstanceFromClusterProps> = ({
	clusteredStepInstances,
	children,
}) => {
	const { workspaceId } = Route.useParams();
	const [dayWidthMultiplier, setDayWidthMultiplier] = useState([1]);
	const startDate = getStartDate(clusteredStepInstances.minDateTime);

	const maxDate = endOfDay(new Date(clusteredStepInstances.maxDateTime));
	const endDate = format(maxDate, "yyyy-MM-dd");

	// these could be consolidated into a single helper
	const defaultRange = getDefaultTimeRange(startDate, endDate);
	const numberOfDays = durationInDays(startDate, endDate);
	const dayWidth = getDayWidth(defaultRange, numberOfDays);

	const channels: Channel[] = useMemo(
		() =>
			clusteredStepInstances?.groups.map((g) => ({
				id: g.resource.id,
				uuid: g.resource.id,
				title: g.resource.name,
				treeName: g.resource.tree.name,
				logo: "",
				isNestedChild: false,
			})),
		[clusteredStepInstances],
	);

	const epg: Program[] = useMemo(
		() =>
			clusteredStepInstances?.groups.flatMap((g) =>
				g.stepInstances.map((s) => ({
					channelUuid: g.resource.id,
					id: s.id,
					title: s.name,
					type: s.type,
					labels: s.businessDimension.labels,
					since: format(
						s.schedule.plannedAt ?? s.schedule.scheduleLowerBand,
						"yyyy-MM-dd'T'HH:mm:ss",
					),
					till: format(s.schedule.scheduleUpperBand, "yyyy-MM-dd'T'HH:mm:ss"),
					workspaceId,
					assignments: s.assignments,
					modified: Boolean(s.schedule.plannedAt), // means the schedule has been modified by the regulator
					resolution: s.resolution,
				})),
			),
		[clusteredStepInstances, workspaceId],
	);

	const { getEpgProps, getLayoutProps } = useEpg({
		channels,
		epg,
		startDate,
		// endDate,
		theme: planbyLightTheme,
		mode: { type: defaultRange, style: "default" },
		overlap: {
			enabled: true,
			mode: "stack",
		},
		sidebarWidth: 180,
		dayWidth: dayWidth * dayWidthMultiplier[0],
		timezone: {
			enabled: true,
			mode: "utc",
			zone: "Europe/Paris",
		},
	});

	return (
		<>
			<div className="flex flex-row items-center justify-between py-1">
				{children}
				<Slider
					className="w-52 ms-auto py-2 pe-2"
					onValueChange={setDayWidthMultiplier}
					value={dayWidthMultiplier}
					min={1}
					max={10}
					step={1}
				/>
			</div>

			<div className="bg-grid-scheduler">
				<Epg {...getEpgProps()}>
					<Layout
						{...getLayoutProps()}
						renderProgram={({ program, ...rest }) => (
							<CustomItem
								key={program.data.id}
								program={program}
								showStepType={false}
								{...rest}
							/>
						)}
						renderChannel={({ channel, ...rest }) => (
							<CustomChannelItem
								key={channel.uuid}
								channel={channel}
								{...rest}
							/>
						)}
					/>
				</Epg>
			</div>
		</>
	);
};

const StepInstanceFromClusterList: FC<
	Pick<StepInstanceFromClusterProps, "clusteredStepInstances">
> = ({ clusteredStepInstances }) => {
	return (
		<div className="flex flex-col gap-2">
			{clusteredStepInstances.groups.map((g) => (
				<Collapsible defaultOpen key={g.resource.id}>
					<CollapsibleTrigger className="p-2 flex items-center gap-2 [&>svg]:data-[state=open]:rotate-90">
						<ChevronRightIcon className="inline" />
						{g.resource.name}
						<Badge variant="secondary" className="text-slate-400">
							{g.resource.tree.name}
						</Badge>
					</CollapsibleTrigger>
					<CollapsibleContent className="ps-8 flex flex-col gap-2">
						{g.stepInstances.map((s) => (
							<div
								key={s.id}
								className="flex items-center gap-2 w-fit bg-white rounded shadow-sm p-2"
							>
								{s.resolution === "done" ? (
									<CheckCircledIcon className="inline text-primary" />
								) : (
									<CircleIcon className="inline scale-90" />
								)}
								<span className="pe-2 font-semibold">
									{s.businessDimension.name}
								</span>
								<CustomItemSchedule
									stepId={s.id}
									since={s.schedule.plannedAt ?? s.schedule.scheduleLowerBand}
									till={s.schedule.resolvedAt ?? s.schedule.scheduleLowerBand}
									modified={s.schedule.scheduleByRegulator}
									dateFormatter={(date: string | number | Date) =>
										format(date, "HH:MM").toLowerCase()
									}
								/>
								<AvatarsList
									numberToDisplay={3}
									users={s.assignments}
									size={26}
								/>
							</div>
						))}
					</CollapsibleContent>
				</Collapsible>
			))}
		</div>
	);
};

const SelectResource: React.FC<{ resources: ResourceAllocation }> = ({
	resources,
}) => {
	const { selectedResource } = Route.useSearch();
	const navigate = useNavigate();

	const updateResourceParams = useCallback(
		(selectedResource: string) => {
			navigate({
				search: {
					selectedResource,
				},
			});
		},
		[navigate],
	);

	return (
		<Select
			onValueChange={updateResourceParams}
			value={selectedResource ?? resources[0].stepTemplateId}
		>
			<SelectTrigger className="w-72">
				<SelectValue />
			</SelectTrigger>
			<SelectContent>
				{resources.map((r) => (
					<SelectItem key={r.stepTemplateId} value={r.stepTemplateId}>
						{r.stepTemplateName}
					</SelectItem>
				))}
			</SelectContent>
		</Select>
	);
};

const ClusterHeader = ({ streamId }) => {
	const { data: resources, isLoading: isResourceLoading } =
		useProcessStreamResourceAllocationDefinitions(streamId);
	const { selectedCluster } = Route.useSearch();

	const stepTemplateId = resources?.length
		? resources[0].stepTemplateId
		: undefined;

	const nodeReferenceDefinitionId = resources?.length
		? resources[0].nodeReferenceId
		: undefined;

	const { data: definitions } = useMetadataDefinitions({
		processStreamId: streamId,
	});

	const { data: clusteredStepInstances, isLoading } =
		useStepInstancesFromCluster(
			streamId,
			selectedCluster,
			stepTemplateId,
			nodeReferenceDefinitionId,
		);
	if (isLoading || isResourceLoading) return <LoadingMetronome />;

	if (resources?.length === 1 && clusteredStepInstances?.groups?.length) {
		return (
			<Tabs defaultValue="timeline" className="">
				<div className="flex items-center gap-2">
					<TabsList className="bg-transparent">
						<TabsTrigger
							className="py-2 data-[state=active]:bg-white"
							value="timeline"
						>
							<CalendarIcon />
						</TabsTrigger>
						<TabsTrigger
							className="py-2 data-[state=active]:bg-white"
							value="List"
						>
							<ActivityLogIcon />
						</TabsTrigger>
					</TabsList>
					<SelectResource resources={resources} />
				</div>
				<TabsContent value="timeline">
					<StepInstanceFromCluster
						clusteredStepInstances={clusteredStepInstances}
					/>
				</TabsContent>
				<TabsContent value="List">
					<StepInstanceFromClusterList
						clusteredStepInstances={clusteredStepInstances}
					/>
				</TabsContent>
			</Tabs>
		);
	}
	if (resources?.length === 0)
		return <span>{"no resource allocation found"}</span>;
	return <span>{"no step instances were loaded"}</span>;
};

const ClusterMenu = ({
	streamId,
}: {
	streamId: string;
}) => {
	const { data: clusters } = useClusters(streamId);
	const navigate = useNavigate();
	const { selectedCluster } = Route.useSearch();

	useEffect(() => {
		if (!selectedCluster && clusters?.length) {
			navigate({
				search: {
					selectedCluster: clusters[0]?.id,
				},
			});
		}
	}, [selectedCluster, clusters, navigate]);

	if (clusters)
		return (
			<ul className="flex flex-row items-center gap-4">
				{clusters?.map((b) => {
					return (
						<li
							className={clsx({
								"rounded font-semibold border ": true,
								"bg-white text-primary border-primary border-solid":
									selectedCluster === b.id,
								"border-dashed text-slate-500 border-slate-400":
									selectedCluster !== b.id,
							})}
							key={b.id}
						>
							<button
								className="px-2 py-1.5 "
								type="button"
								onClick={() =>
									navigate({
										search: {
											selectedCluster:
												selectedCluster === b.id ? undefined : b.id,
										},
									})
								}
							>
								{b.context.name}
								<Badge
									variant={selectedCluster === b.id ? "default" : "outline"}
									className={
										selectedCluster === b.id
											? "text-xs px-1 ms-2 text-white border-primary"
											: "text-xs px-1 ms-2 text-slate-500 bg-white"
									}
								>
									{b.state}
								</Badge>
							</button>
						</li>
					);
				})}
			</ul>
		);
	return null;
};

type ClusterPageProps = {
	children: React.ReactNode;
	isLoading: boolean;
	processStream: IProcessStream;
};
const ClusterPage: FC<ClusterPageProps> = ({
	children,
	isLoading,
	processStream,
}) => {
	const { workspaceId } = Route.useParams();
	return (
		<>
			<ProcessStreamSummary
				isLoading={isLoading}
				processStream={processStream}
				link={
					<Link
						from="/$workspaceId/stream/$streamId/clustered"
						to="/$workspaceId/stream/$streamId/routine"
						params={{
							streamId: processStream.id,
							workspaceId,
						}}
						search={{}}
						className="ps-2"
					>
						<FormattedMessage id="VIEW_PROCESSES" />
					</Link>
				}
			/>
			<div>
				<ClusterMenu streamId={processStream.id} />
				<div className="pt-8 ">{children}</div>{" "}
			</div>
		</>
	);
};

const ClusteredParams = v.object({
	selectedCluster: v.optional(v.string()),
	selectedResource: v.optional(v.string()),
});

export const Route = createFileRoute(
	"/$workspaceId/stream/$streamId/clustered",
)({
	validateSearch: (searchParams) => v.parse(ClusteredParams, searchParams),
	loader: async ({ context, params }) => {
		const { queryClient } = context;
		const { streamId, workspaceId } = params;

		const promises = [];

		promises.push(
			queryClient.ensureQueryData(
				singleProcessStreamsOptions(workspaceId, streamId),
			),
		);

		await Promise.all(promises);
		return;
	},
	component: () => {
		const { streamId, workspaceId } = Route.useParams();
		const { data: processStream, isLoading } = useQuery(
			singleProcessStreamsOptions(workspaceId, streamId),
		);
		return (
			<ClusterPage isLoading={isLoading} processStream={processStream}>
				<ClusterHeader streamId={streamId} />
			</ClusterPage>
		);
	},
});
