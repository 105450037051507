import { useUpdateStartDate } from "@metronome/api/useProcessInstance";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import { Lozenge } from "@metronome/components/Lozenge";
import { DatePicker } from "@metronome/components/ui/datePicker";
import { DropdownMenuItem } from "@metronome/components/ui/dropdown-menu";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import { formatTimeZoneToDateString } from "@metronome/utils/dateHelpers";
import { ClockIcon } from "@radix-ui/react-icons";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type ProcessTimelineDialogProps = {
	processInstance: IProcessInstance;
};

export const ProcessTimelineDialog: React.FC<ProcessTimelineDialogProps> = ({
	processInstance,
}) => {
	const [open, setOpen] = useState(false);
	const intl = useIntl();

	const { mutate: mutateDueDate, isPending } = useUpdateStartDate(
		processInstance.id,
	);

	const handlePlannedOnChange = (value?: Date): void => {
		if (!value) return;
		mutateDueDate(value);
	};

	const processDetails = [
		{
			title: intl.formatMessage({
				id: "SCHEDULE.START_DATE",
			}),
			dateValue: new Date(processInstance?.schedule?.lowerTimeBand ?? ""),
			value: processInstance?.schedule?.lowerTimeBand
				? formatTimeZoneToDateString(
						processInstance.schedule.lowerTimeBand,
						intl,
					).date
				: "...",
			id: "lowerTimeBand",
			editable: true,
			tag: processInstance.startDateModified ? (
				<Lozenge appearance="default">
					{intl.formatMessage({
						id: "MODIFIED",
					})}
				</Lozenge>
			) : undefined,
			inputType: "date",
			isPending,
			onValidate: handlePlannedOnChange,
		},
		{
			title: intl.formatMessage({
				id: "SCHEDULE.END_DATE",
			}),
			value: processInstance?.schedule?.upperTimeBand
				? formatTimeZoneToDateString(
						processInstance.schedule.upperTimeBand,
						intl,
					).date
				: "...",
			id: "upperTimeBand",
		},
		{
			title: intl.formatMessage({
				id: "SCHEDULE.STARTED_ON",
			}),
			value: processInstance?.schedule?.startedAt
				? formatTimeZoneToDateString(processInstance.schedule.startedAt, intl)
						.date
				: "...",
			id: "startedAt",
		},
		{
			title: intl.formatMessage({
				id: "SCHEDULE.CLOSED_ON",
			}),
			value: processInstance?.schedule?.closedAt
				? formatTimeZoneToDateString(processInstance.schedule.closedAt, intl)
						.date
				: "...",
			id: "closedAt",
		},
	];

	return (
		<Dialog modal open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<DropdownMenuItem
					onSelect={(e) => {
						e.preventDefault();
						setOpen(true);
					}}
				>
					<ClockIcon className="me-2" />
					<FormattedMessage id="TIMELINE" />
				</DropdownMenuItem>
			</DialogTrigger>
			<DialogContent size="small">
				<div>
					<div className="flex flex-col items-start gap-2">
						<span className="font-semibold text-slate-900">
							<FormattedMessage id="TIMELINE" />
						</span>
						{processDetails
							.filter((val) => val.value)
							.map((detail) => (
								<div key={detail.id} className="flex flex-col relative">
									<span className="uppercase text-xs font-semibold text-slate-500">
										{detail.title}
									</span>
									{!detail.editable && <span>{detail.value}</span>}
									{!!detail.editable && (
										<div className="flex flex-row items-center gap-2">
											{detail.dateValue && (
												<DatePicker
													key={detail.id}
													initialDate={detail.dateValue}
													onValidate={detail.onValidate}
												/>
											)}
											{detail.tag}
										</div>
									)}
								</div>
							))}
					</div>
				</div>
			</DialogContent>
		</Dialog>
	);
};
