import {
	type SchedulePayload,
	useUpdateStepInstanceSchedule,
} from "@metronome/api/useStepInstance";
import { ColumnList, type IList } from "@metronome/components/ColumnList";
import DateTimeCell from "@metronome/components/DateCell";
import { StepInstanceIcon } from "@metronome/components/IconStepInstance";
import {
	Alert,
	AlertDescription,
	AlertTitle,
} from "@metronome/components/ui/alert";
import type { IStepInstance } from "@metronome/types/StepInstance";
import { formatDurationToString } from "@metronome/utils/durationHelpers";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";
import { format, parseISO } from "date-fns";
import type { FC } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type StepSummaryInfoProps = {
	stepInstance: IStepInstance;
};

export const StepSummaryInfo: FC<StepSummaryInfoProps> = ({
	stepInstance,
}): JSX.Element => {
	const intl = useIntl();

	const { mutate } = useUpdateStepInstanceSchedule(
		stepInstance.id,
		stepInstance.processInstance.id,
	);

	const handlePlannedOnChange = ({
		plannedDateTime,
		plannedDuration,
	}: SchedulePayload): void => {
		if (!plannedDateTime) return;
		mutate({ plannedDateTime, plannedDuration });
	};

	const targetStart = parseISO(stepInstance.schedule.scheduleLowerBand);
	const deadline = parseISO(stepInstance.schedule.scheduleUpperBand);
	const deadlineIsBeforeDueDate = deadline < targetStart;
	const maybeResolutionDate = stepInstance.schedule?.plannedAt;
	const resolutionDate = maybeResolutionDate
		? parseISO(maybeResolutionDate)
		: undefined;

	const stepSummaryBlock: IList[] = [
		{
			title: intl.formatMessage({ id: "STEP_INSTANCE.TYPE" }),
			value: (
				<div className="flex flex-row gap-2 items-center">
					<StepInstanceIcon type={stepInstance.type} />
					{intl.formatMessage({
						id: `STEP_INSTANCE.TYPE.${stepInstance.type}`,
					})}
				</div>
			),
			id: "type",
		},
		{
			title: intl.formatMessage({ id: "STEP_INSTANCE.DURATION" }),
			value: formatDurationToString(stepInstance.schedule?.estimatedDuration),
			id: "duration",
		},
		{
			title: intl.formatMessage({
				id: `STEP_INSTANCE.TARGET_START.${stepInstance.type.toUpperCase()}`,
			}),
			value: format(targetStart, "y-MM-d"),
			dateValue: targetStart,
			editable: stepInstance.processInstance.state !== "closed",
			inputType: "date",
			showTime: stepInstance.showTime,
			onValidate: (val) => handlePlannedOnChange({ plannedDateTime: val }),
			tag: stepInstance.dueDateModified ? (
				<span className="italic text-xs">
					{intl.formatMessage({
						id: "MODIFIED",
					})}
				</span>
			) : undefined,
			id: `dueOn-${stepInstance.id}`,
		},
		{
			title: intl.formatMessage({
				id: `STEP_INSTANCE.DEADLINE.${stepInstance.type.toUpperCase()}`,
			}),
			value:
				stepInstance.type !== "milestone"
					? format(deadline, "y-MM-d")
					: undefined,
			dateValue: deadline,
			editable: false,
			inputType: "date",
			showTime: stepInstance.showTime,
			tag: stepInstance.notLaterThanModified ? (
				<span className="italic text-xs">
					{intl.formatMessage({
						id: "MODIFIED",
					})}
				</span>
			) : undefined,
			id: `notLaterThan-${stepInstance.id}`,
		},
		{
			title: intl.formatMessage({
				id: `STEP_INSTANCE.RESOLUTION_DATE.${stepInstance.type.toUpperCase()}`,
			}),
			value: resolutionDate
				? format(resolutionDate, "y-MM-d'T'HH:mm:ss")
				: undefined,
			dateValue: resolutionDate,
			editable: stepInstance.processInstance.state !== "closed",
			inputType: "date",
			// onValidate: (date) => handlePlannedOnChange("resolution-date", date),
			id: `resolutionDate-${stepInstance.id}`,
			showTime: true,
		},
		{
			title: intl.formatMessage({ id: "PERFORMED_AT" }),
			value: stepInstance.completion?.performedAt ? (
				<DateTimeCell datetime={stepInstance.completion.performedAt} />
			) : (
				""
			),
			id: "performedAt",
		},
	];
	return (
		<>
			<ColumnList list={stepSummaryBlock} />
			{deadlineIsBeforeDueDate && (
				<Alert className="mt-4" variant="destructive">
					<ExclamationTriangleIcon className="h-4 w-4" />
					<AlertTitle>
						<FormattedMessage id="WARNING" />
					</AlertTitle>
					<AlertDescription>
						<FormattedMessage id="DEADLINE_BEFORE_START" />
					</AlertDescription>
				</Alert>
			)}
		</>
	);
};
