import { useStages } from "@metronome/api/useProcessInstance";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { ErrorBoundary } from "react-error-boundary";
import {
	COMPACT_SIZE,
	EXPANDED_SIZE,
	planbyLightTheme,
} from "@metronome/constants/planbyTheme";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IStage } from "@metronome/types/Gate";
import {
	useEpg,
	Epg,
	Layout,
	ChannelBox,
	type ChannelItem,
} from "@nessprim/planby-pro";
import type {
	Area,
	Mode,
	ProgramWithOmittedUuid,
} from "@nessprim/planby-pro/dist/Epg/helpers";
import * as duration from "duration-fns";
import { type FC, useMemo, useState } from "react";
import { Link, useNavigate } from "@tanstack/react-router";
import { CustomItem } from "./planby/CustomItem";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@metronome/components/ui/select";
import { FormattedMessage } from "react-intl";
import { addDays, endOfDay, format, startOfDay } from "date-fns";
import {
	EStepInstanceType,
	type IStepInstanceLight,
} from "@metronome/types/StepInstance";
import type { IStep } from "@metronome/types/Step";
import { CustomTimeline } from "./planby/CustomTimeline";
import {
	durationInDays,
	getAvailableRange,
	getDayWidth,
	getDefaultTimeRange,
	getStartDate,
	globalStyles,
} from "@metronome/utils/planby";
import { SchedulerError } from "@metronome/components/SchedulerError";
import { Slider } from "@metronome/components/ui/slider";

const CustomChannelItem = ({ channel }: ChannelItem) => {
	const workspaceId = useWorkspaceId();
	const { position, title, uuid } = channel;
	return (
		<ChannelBox className="p-2 text-center bg-slate-400" {...position}>
			<Link
				to="/$workspaceId/process/$processId"
				params={{ workspaceId, processId: uuid }}
			>
				<span className="text-slate-600 font-semibold">{title}</span>
			</Link>
		</ChannelBox>
	);
};

type SchedulerWrapperProps = {
	id: string;
	name: string;
	plannedStart?: string;
	plannedEnd?: string;
};
export const SchedulerWrapper: FC<SchedulerWrapperProps> = ({
	id,
	name,
	plannedStart,
	plannedEnd,
}) => {
	const { data: gates } = useStages(id);

	if (gates?.length) {
		return (
			<Scheduler
				key={id}
				gates={gates}
				id={id}
				name={name}
				plannedStart={plannedStart}
				plannedEnd={plannedEnd}
			/>
		);
	}
	return <LoadingMetronome />;
};

type SchedulerProps = {
	gates: IStage[];
	id: string;
	name: string;
	plannedStart?: string;
	plannedEnd?: string;
};

// if the previous milestone has exactly the same schedule
// we add an offset so they don't visually stack on top of each other
function getPreviousMilestone(
	stepIndex: number,
	array: Array<IStep>,
): Array<IStepInstanceLight> | undefined {
	let prevStepInstances: Array<IStepInstanceLight> | undefined = undefined;
	for (let index = stepIndex - 1; index > 0; index--) {
		const el = array[index];
		if (el.stepInstances[0].type === EStepInstanceType.enum.milestone) {
			prevStepInstances = el.stepInstances;
			break;
		}
	}
	return prevStepInstances;
}

function hasPreviousMilestoneSameSchedule(
	prevStep: Array<IStepInstanceLight>,
	targetStart: string,
): boolean {
	const hasSameStartAsPrevStep =
		prevStep[0]?.schedule.scheduleLowerBand === targetStart;
	return hasSameStartAsPrevStep;
}

const Scheduler: FC<SchedulerProps> = ({
	gates,
	id,
	name,
	plannedStart,
	plannedEnd,
}) => {
	const navigate = useNavigate();
	const workspaceId = useWorkspaceId();
	const channels = useMemo(
		() => [
			{
				logo: "",
				uuid: id,
				title: name,
			},
		],
		[id, name],
	);
	const [displayMode, setDisplayMode] = useState<"compact" | "expanded">(
		"expanded",
	);

	const [dayWidthMultiplier, setDayWidthMultiplier] = useState([1]);

	const data = useMemo(() => {
		const areas: Area[] = [];
		const epg: ProgramWithOmittedUuid[] = [];
		let offsetY = 0;
		gates?.forEach(({ steps }) => {
			let firstMilestoneSeen = false;

			return steps?.forEach((step, stepIndex, array) =>
				step.stepInstances.forEach((instance) => {
					const { scheduleLowerBand, scheduleUpperBand } = instance.schedule;
					const { type } = instance;

					const sinceRaw = format(scheduleLowerBand, "yyyy-MM-dd'T'HH:mm:ss");

					const tillRaw =
						format(scheduleUpperBand, "yyyy-MM-dd'T'HH:mm:ss") ?? "";

					const durationRaw = duration.between(sinceRaw, tillRaw);
					const durationInMili = duration.toMilliseconds(durationRaw);

					const since =
						durationInMili === 0
							? startOfDay(sinceRaw).toISOString()
							: sinceRaw;
					const till =
						durationInMili === 0 ? endOfDay(tillRaw).toISOString() : tillRaw;
					if (type === EStepInstanceType.enum.milestone) {
						if (!firstMilestoneSeen) {
							offsetY = 0;
						}
						firstMilestoneSeen = true;

						if (stepIndex >= 1) {
							const prevStepInstances = getPreviousMilestone(stepIndex, array);
							if (prevStepInstances && Array.isArray(prevStepInstances)) {
								const sameSchedule = hasPreviousMilestoneSameSchedule(
									prevStepInstances,
									scheduleLowerBand,
								);

								if (sameSchedule) {
									offsetY += 40;
								} else {
									offsetY = 0;
								}
							}
						}
						areas.push({
							startDate: since,
							endDate: till,
							onClick: () =>
								navigate({
									to: "/$workspaceId/process/$processId/gates-and-steps/$stepId",
									params: {
										workspaceId,
										processId: id,
										stepId: step.stepInstances[0].id,
									},
								}),
							styles: {
								background: "#00800012",
								borderLeft: "2px dotted #38A169",
								transform: `translateY(${offsetY}px)`,
								zIndex: "100",
							},
							annotations: {
								styles: {
									background: "#38A169",
									color: "white",
									transform: `translateY(${offsetY}px)`,
								},
								textStart: step.name,
							},
						});
					} else {
						epg.push({
							workspaceId,
							id: instance.id,
							channelUuid: id,
							image: "",
							since,
							till,
							title: instance.businessDimension.name,
							type,
						});
					}
				}),
			);
		});
		return { epg, areas };
	}, [gates, id, workspaceId, navigate]);

	const { epg, areas } = data;

	if (!plannedStart || !plannedEnd) {
		throw new Error("plannedStart or plannedEnd not defined");
	}

	const defaultRange = getDefaultTimeRange(plannedStart, plannedEnd);
	const [rangeMode, setRangeMode] = useState<Mode["type"]>(defaultRange);

	const availableRanges = getAvailableRange(defaultRange);
	const [timelineRangeInHours, setTimelineRangeInHours] = useState(2);

	const numberOfDays = durationInDays(plannedStart, plannedEnd);
	const startDate = getStartDate(plannedStart);

	const endDate = rangeMode === "day" ? plannedEnd : endOfDay(plannedEnd);
	let endDateFormatted = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");

	if (!(new Date(startDate) <= new Date(endDate))) {
		console.log("Err ~ !! startDate after the deadline !!");
		// here we'll overwrite the endDate to be later than the startDate to prevent planby to crash
		// we're not going to render the planby componant but instead show an error message
		endDateFormatted = format(addDays(startDate, 1), "yyyy-MM-dd'T'HH:mm:ss");
	}

	const { getEpgProps, getLayoutProps } = useEpg({
		epg,
		areas,
		channels,
		startDate,
		endDate: endDateFormatted,
		mode: { type: rangeMode, style: "modern" },
		sidebarWidth: 100,
		dayWidth: getDayWidth(rangeMode, numberOfDays) * dayWidthMultiplier[0],
		itemHeight: displayMode === "expanded" ? EXPANDED_SIZE : COMPACT_SIZE,
		isSidebar: false,
		isTimeline: true,
		isLine: true,
		isCurrentTime: true,
		theme: planbyLightTheme,
		overlap: {
			enabled: true,
			mode: "stack",
		},
		globalStyles,
	});

	if (!(new Date(startDate) <= new Date(endDate))) {
		return <SchedulerError />;
	}

	return (
		<ErrorBoundary fallback={<SchedulerError />}>
			<div>
				<div className="flex gap-2 items-center">
					<Select
						value={rangeMode}
						onValueChange={(value: Mode["type"]) => setRangeMode(value)}
					>
						<SelectTrigger className="w-fit">
							<SelectValue placeholder="Select range" />
						</SelectTrigger>
						<SelectContent>
							{availableRanges.map((range) => (
								<SelectItem key={range} value={range}>
									<FormattedMessage id={range.toUpperCase()} />
								</SelectItem>
							))}
						</SelectContent>
					</Select>
					<Select
						onValueChange={(val: string) =>
							setTimelineRangeInHours(Number(val))
						}
						value={String(timelineRangeInHours)}
					>
						<SelectTrigger className="w-fit">
							<SelectValue placeholder="Select time range" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="0">Every hour</SelectItem>
							<SelectItem value="2">Every two hours</SelectItem>
							<SelectItem value="4">Every four hours</SelectItem>
							<SelectItem value="6">Every six hours</SelectItem>
						</SelectContent>
					</Select>
					<Select
						value={displayMode}
						onValueChange={(value: "compact" | "expanded") =>
							setDisplayMode(value)
						}
					>
						<SelectTrigger className="me-auto w-fit">
							<SelectValue placeholder="Select mode" />
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="compact">
								<FormattedMessage id="COMPACT" />
							</SelectItem>
							<SelectItem value="expanded">
								<FormattedMessage id="EXPANDED" />
							</SelectItem>
						</SelectContent>
					</Select>
					<Slider
						className="w-52 ms-auto py-2 pe-2"
						onValueChange={setDayWidthMultiplier}
						value={dayWidthMultiplier}
						min={1}
						max={10}
						step={1}
					/>
				</div>
				<Epg {...getEpgProps()}>
					<Layout
						{...getLayoutProps()}
						renderChannel={({ channel, ...rest }) => (
							<CustomChannelItem
								key={channel.uuid}
								channel={channel}
								{...rest}
							/>
						)}
						renderProgram={({ program, ...rest }) => (
							<CustomItem key={program.data.id} program={program} {...rest} />
						)}
						renderTimeline={
							rangeMode === "day" && timelineRangeInHours > 0
								? (props) => (
										<CustomTimeline
											timelineRangeInHours={timelineRangeInHours}
											{...props}
										/>
									)
								: undefined
						}
					/>
				</Epg>
			</div>
		</ErrorBoundary>
	);
};
