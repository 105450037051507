import type React from "react";
import { Avatar } from "@metronome/components/Avatar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { getInitials } from "@metronome/utils/formatText";
import { useName, getUserInfoUri } from "@metronome/utils/user";
import type { IParty } from "@metronome/types/Party";
import { CircleIcon } from "@radix-ui/react-icons";
import { Select, SelectContent, SelectItem, SelectTrigger } from "./ui/select";

const UserPopoverComplete: React.FC<{ user: IParty; size?: number }> = ({
	user,
	size = 32,
}) => {
	const initials = getInitials(user?.firstName, user?.lastName);
	return (
		<Popover>
			<PopoverTrigger className="p-0 m-0 bg-transparent">
				<Avatar size={size} src={getUserInfoUri(user)} alt={initials} />
			</PopoverTrigger>
			<PopoverContent>
				<div className="flex items-center gap-2 text-base">
					<Avatar
						size={48}
						src={getUserInfoUri(user)}
						alt={getInitials(user?.firstName, user?.lastName)}
					/>
					<div className="truncate">
						<p className="font-bold">{useName(user)}</p>
						<a className="text-xs" href={`mailto:${user.email}`}>
							{user.email}
						</a>
					</div>
				</div>
				<div className="pt-4 pb-2 grid grid-cols-2 gap-2">
					<Select>
						<SelectTrigger>
							<CircleIcon className="inline text-green-600 me-1 align-text-bottom	" />
							<span className="text-green-600 font-semibold">Disponible</span>
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="unavailable">not available</SelectItem>
							<SelectItem value="rejected">Rejected</SelectItem>
						</SelectContent>
					</Select>
					<Select>
						<SelectTrigger>
							<span className="">Cleaner</span>
						</SelectTrigger>
						<SelectContent>
							<SelectItem value="unavailable">another role</SelectItem>
							<SelectItem value="rejected">some role</SelectItem>
						</SelectContent>
					</Select>
				</div>
				<span className="text-slate-600">source: Planning</span>
			</PopoverContent>
		</Popover>
	);
};

export const UserPopover: React.FC<{ user: IParty; size?: number }> = ({
	user,
	size,
}) => {
	if (user) return <UserPopoverComplete user={user} size={size} />;
	return <span />;
};
