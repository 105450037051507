import { z } from "zod";
import { IDoer } from "./Doer";
import { IModelReference } from "./ModelReference";

const EBusinessDimensionType = z.enum([
	"products",
	"geography",
	"account",
	"factory",
	"warehouse",
	"supplier",
	"project",
	"generic",
	"reporting",
]); 

type EBusinessDimensionType = z.infer<typeof EBusinessDimensionType>;

export const IBusinessDimension = z.object({
	id: z.string().min(1),
	name: z.string(),
	description: z.string().optional(),
	type: EBusinessDimensionType,
});

export type IBusinessDimension = z.infer<typeof IBusinessDimension>;

export const IMetadataValue = z.object({
	id: z.string().min(1),
	definition: IModelReference,
	value: z.union([z.array(z.string()), z.string()]),
	createdBy: IDoer,
});

export type IMetadataValue = z.infer<typeof IMetadataValue>;

export const INodeReferenceSpec = z.object({
	id: z.string().min(1),
	treeId: z.string(),
	referencedTreeId: z.string(),
	referencedTreeName: z.string(),
	nodes: z.array(IModelReference),
});

export type INodeReferenceSpec = z.infer<typeof INodeReferenceSpec>;

export const IBusinessDimensionNode = z.object({
	id: z.string().min(1),
	name: z.string(),
	description: z.string().optional(),
	tree: IBusinessDimension,
	labels: z.array(z.string()),
	metadataValues: z.array(IMetadataValue),
	nodeType: IModelReference.optional(),
	createdBy: IDoer.optional(),
});

export type IBusinessDimensionNode = z.infer<typeof IBusinessDimensionNode>;

// should be a pick
export const ILightBusinessDimensionNode = IBusinessDimensionNode.pick({
	id: true,
	name: true,
	description: true,
	tree: true,
	metadataValues: true,
});

export type ILightBusinessDimensionNode = z.infer<
	typeof ILightBusinessDimensionNode
>;

export const INodeReference = z.object({
	referencedNode: ILightBusinessDimensionNode,
	referencedSpecId: z.string(),
});
export type INodeReference = z.infer<typeof INodeReference>;
