import { singleProcessStreamsOptions } from "@metronome/api/useProcessStreamInstances";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { PageTitle } from "@metronome/context/PageData";
import { ProcessStreamTemplate } from "@metronome/features/ProcessStreamTemplate";
import { FormattedMessage, useIntl } from "react-intl";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@metronome/components/ui/tabs";
import { ProcessTimelineLoader } from "./-timeline";
import { ActiveViewProvider } from "@metronome/context/ActiveViewData";
import { UserProcessConfigLoader } from "@metronome/features/UserProcessConfigLoader";
import type { ProcessContext } from "@metronome/types/ProcessInstance";
import useUserPreferences from "@metronome/api/useUserPreference";
import { useState } from "react";

export const Route = createFileRoute("/$workspaceId/stream/$streamId/onDemand")(
	{
		loader: async ({ context, params }) => {
			const { queryClient } = context;
			const { streamId, workspaceId } = params;

			const promises = [];

			promises.push(
				queryClient.ensureQueryData(
					singleProcessStreamsOptions(workspaceId, streamId),
				),
			);

			await Promise.all(promises);
			return;
		},
		component: () => {
			const { streamId, workspaceId } = Route.useParams();
			const { data: processStream } = useQuery(
				singleProcessStreamsOptions(workspaceId, streamId),
			);

			return <OnDemandPage processStream={processStream} />;
		},
	},
);
// type TabName = "list" | "timeline";
const OnDemandPage = ({ processStream }) => {
	const [activeTab, setActiveTab] = useState("list");
	const { organizations } = useOrganizationData();
	const intl = useIntl();
	const organization = organizations?.find(
		(orga) => orga.id === processStream?.organization.id,
	);

	const state = "active"; // to refactor
	const PREFERENCE_KEY = `metronome.processStream.instances.active.${processStream.id}`;

	const { data: preferences } = useUserPreferences(PREFERENCE_KEY);

	return (
		<ProcessStreamTemplate processStream={processStream} isLoading={false}>
			<PageTitle organizationName={organization?.name}>
				{intl.formatMessage({ id: "MENU.PROCESS_STREAM" })}
			</PageTitle>
			<Tabs value={activeTab} onValueChange={setActiveTab}>
				<TabsList className="bg-white">
					<TabsTrigger className="font-bold" value="list">
						<FormattedMessage id="LIST" />
					</TabsTrigger>
					<TabsTrigger className="font-bold" value="timeline">
						<FormattedMessage id="TIMELINE" />
					</TabsTrigger>
				</TabsList>
				<TabsContent value="list">
					<ActiveViewProvider preferences={preferences}>
						<UserProcessConfigLoader
							context={state as ProcessContext}
							key={state}
							states={["active"]}
							times={["past", "today"]}
							processStreamId={processStream.id}
							preferenceKey={PREFERENCE_KEY}
							preferences={preferences}
						/>
					</ActiveViewProvider>
				</TabsContent>
				<TabsContent value="timeline">
					<ProcessTimelineLoader processStreamId={processStream.id} />
				</TabsContent>
			</Tabs>
		</ProcessStreamTemplate>
	);
};
