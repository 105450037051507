import { AvatarsList } from "@metronome/components/AvatarsList";
import { StepInstanceIcon } from "@metronome/components/IconStepInstance";
import { Lozenge } from "@metronome/components/Lozenge";
import { EXPANDED_SIZE } from "@metronome/constants/planbyTheme";
import { EStepInstanceType } from "@metronome/types/StepInstance";
import { ETimeliness } from "@metronome/types/Timeliness";
import {
	type ProgramItem,
	useProgram,
	ProgramBox,
	ProgramContent,
	ProgramFlex,
	ProgramStack,
	ProgramTitle,
	ProgramText,
} from "@nessprim/planby-pro";
import { Link } from "@tanstack/react-router";
import { CustomItemSchedule } from "../UpdateStepInstanceSchedule";
import type { EResolution } from "@metronome/types/Resolution";
import { CheckCircledIcon, CircleIcon } from "@radix-ui/react-icons";

type ExtraProps = {
	showStepType?: boolean;
};

export const CustomItem = ({
	program,
	showStepType = true,
	...rest
}: ProgramItem & ExtraProps) => {
	const { isLive, styles, formatTime, set12HoursTimeFormat } = useProgram({
		program,
		...rest,
	});

	const { data } = program;
	const {
		title,
		labels,
		since,
		till,
		type,
		id,
		channelUuid,
		workspaceId,
		doneStepInstances,
		totalStepInstances,
		timeliness,
		assignments,
		modified,
		resolution,
	} = data;

	const timelinessStyle =
		timeliness === ETimeliness.enum.late
			? "from-red-100 to-red-100 !border-red-500"
			: "from-card to-card !border-primary";

	if (type === EStepInstanceType.enum.milestone) {
		return (
			<ProgramBox
				style={styles.position}
				width={styles.width}
				className="translate-x-1/2 !bg-transparent relative flex flex-col text-center items-center justify-center !z-50"
			>
				<div className="scale-150">
					<StepInstanceIcon type={type} />
				</div>
				<span>{title}</span>
			</ProgramBox>
		);
	}

	return (
		<ProgramBox
			width={styles.width}
			style={styles.position}
			className={`!z-[10] overflow-auto ${
				styles.width < 200 ? "hover:!w-[280px]" : ""
			} transition-all duration-300 ease-in-out`}
		>
			<ProgramContent
				className={`!bg-gradient-to-r ${timelinessStyle} !border-2 !p-2`}
				width={styles.width}
				isLive={isLive}
			>
				<ProgramFlex>
					{/* {isLive && isMinWidth && <ProgramImage src={image} alt="Preview" />} */}
					<ProgramStack className="flex flex-col justify-between">
						<ProgramTitle>
							{!!showStepType && (
								<span className="pe-2 text-slate-900">
									<StepInstanceIcon type={type} />
								</span>
							)}
							{(resolution as EResolution) === "done" ? (
								<CheckCircledIcon className="inline pe-1" />
							) : (
								<CircleIcon className="inline scale-90 pe-1" />
							)}
							<Link
								disabled
								className="pe-2 text-slate-900"
								to="/$workspaceId/process/$processId/gates-and-steps/$stepId"
								params={{
									workspaceId,
									processId: channelUuid,
									stepId: id,
								}}
							>
								{title}
							</Link>
							{labels?.map((label) => (
								<Lozenge appearance="default" key={label}>
									{label}
								</Lozenge>
							))}
						</ProgramTitle>
						<div className="flex flex-row items-center">
							{styles.position.height >= EXPANDED_SIZE && (
								<ProgramText className="text-slate-900 pe-4">
									<CustomItemSchedule
										stepId={id}
										since={since}
										till={till}
										modified={modified}
										dateFormatter={(date: string | number | Date) =>
											formatTime(date, set12HoursTimeFormat()).toLowerCase()
										}
									/>
									{totalStepInstances > 1 && (
										<span className="ms-2 px-1 bg-slate-200 rounded">{`${doneStepInstances}/${totalStepInstances}`}</span>
									)}
								</ProgramText>
							)}
							{!!assignments?.length && (
								<AvatarsList
									numberToDisplay={3}
									users={assignments}
									size={26}
								/>
							)}
						</div>
					</ProgramStack>
				</ProgramFlex>
			</ProgramContent>
		</ProgramBox>
	);
};
