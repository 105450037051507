import { ColumnList, type IList } from "@metronome/components/ColumnList";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import type { IProcessStream } from "@metronome/types/ProcessStream";
import type React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/Dialog";
import { DispatchWording } from "../../../components/DispatchWording";
import { NameOrLogo } from "@metronome/components/NameOrLogo";
import { RecurrenceIcon } from "@metronome/components/RecurrenceIcon";
import { Button } from "@metronome/components/ui/button";
import { ThirdApps } from "@metronome/types/ThirdApp";

export const ProcessStreamSummary: React.FC<{
	processStream: IProcessStream;
	isLoading: boolean;
	link?: React.ReactNode;
}> = ({ processStream, isLoading, link }) => {
	const intl = useIntl();

	const processDetails: IList[] = processStream.currentProcessTemplateVersion
		? [
				{
					title: intl.formatMessage({ id: "PROCESS_TEMPLATE.DESCRIPTION" }),
					value: processStream.currentProcessTemplateVersion.description,
					id: "description",
				},
				{
					title: intl.formatMessage({ id: "PROCESS_TEMPLATE.CATEGORY" }),
					value: processStream.currentProcessTemplateVersion.category,
					id: "category",
				},
				{
					title: intl.formatMessage({ id: "PROCESS_TEMPLATE.FREQUENCY" }),
					value: (
						<div className="flex flex-row gap-2 items-center ms-2">
							<RecurrenceIcon recurrence={processStream.recurrence} />
							<strong className="font-medium">
								{processStream.recurrence}
							</strong>
						</div>
					),
					id: "frequency",
				},
				{
					title: intl.formatMessage({ id: "VERSION_NAME" }),
					value: processStream.currentProcessTemplateVersion?.versionName,
					id: "versionName",
				},
				{
					title: intl.formatMessage({ id: "AUTO_CLOSE" }),
					value: (
						<div className="flex flex-row gap-2 items-center">
							<strong className="font-medium">
								{processStream.autoClose > 0
									? intl.formatMessage(
											{ id: "AUTO_CLOSE.MESSAGE" },
											{
												nbDays: processStream.autoClose,
											},
										)
									: intl.formatMessage({ id: "DISABLED" })}
							</strong>
						</div>
					),
					id: "autoClose",
				},
			]
		: [];

	if (isLoading) {
		return <LoadingMetronome />;
	}

	return (
		<div className="flex justify-between items-center my-4">
			<div>
				<div className="flex flex-col items-center md:flex-row gap-4">
					<div className="flex flex-row gap-1 items-center ms-1">
						<RecurrenceIcon recurrence={processStream.recurrence} />
						<h1 className="text-lg mb-0 font-bold">{processStream.name}</h1>
						{link}
					</div>
					{processStream.origins
						?.filter((o) => o !== ThirdApps.Enum.Default)
						.map((o) => (
							<NameOrLogo key={o} name={o} />
						))}
				</div>
			</div>
			<div className="flex flex-col md:flex-row items-center gap-2">
				<Dialog>
					<DialogTrigger asChild>
						<Button variant="ghost">
							<FormattedMessage id="VIEW_STREAM_DETAILS" />
						</Button>
					</DialogTrigger>
					<DialogContent>
						<div className="flex flex-col gap-2">
							<strong>
								<FormattedMessage id="PROCESS_INSTANCE_DETAILS_INFO" />
							</strong>
							<DispatchWording dispatch={processStream.dispatch} />
							<ColumnList list={processDetails} />
						</div>
					</DialogContent>
				</Dialog>
			</div>
		</div>
	);
};
