import type { IDoer } from "@metronome/types/Doer";
import { getInitialsByName } from "@metronome/utils/formatText";
import { getUserUri } from "@metronome/utils/user";
// @ts-ignore
import Logo from "../assets/logotype.svg?react";
// @ts-ignore
import logo from "../assets/logo-white-alt.svg?react";
import {Avatar} from "./Avatar";
import { NameOrLogo } from "./NameOrLogo";

const DisplayDoerType: React.FC<{ doer: IDoer }> = ({ doer }) => {
	if (doer.type === "none") {
		return (
			<div className="w-8 h-8 rounded-full bg-primary p-1.5">
				<Logo />
			</div>
		);
	}
	if (doer.type === "party") {
		return (
			<Avatar
				size={32}
				src={getUserUri(doer)}
				alt={getInitialsByName(doer.name)}
			/>
		);
	}
	if (doer.type === "metronomeSupport") {
		return <Avatar size={32} src={logo} isLogo={true} />;
	}
	if (doer.type === "system") {
		return <NameOrLogo key={doer.name} name={doer.name} />;
	}
	return null;
};

export default DisplayDoerType;
