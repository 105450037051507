import { useUpdateStepInstanceSchedule } from "@metronome/api/useStepInstance";
import { Button } from "@metronome/components/ui/button";
import { Label } from "@metronome/components/ui/label";
import { TimePickerInput } from "@metronome/components/ui/timePickerInput";
import { useFormatDurationToString } from "@metronome/utils/formatDuration";
import {
	ArrowRightIcon,
	ClockIcon,
	ExclamationTriangleIcon,
	Pencil1Icon,
} from "@radix-ui/react-icons";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";

type CustomItemScheduleProps = {
	since: string | number | Date;
	till: string | number | Date;
	stepId: string;
	modified: boolean;
	dateFormatter: (date: string | number | Date) => string;
};
export const CustomItemSchedule: React.FC<CustomItemScheduleProps> = ({
	since,
	till,
	stepId,
	modified,
	dateFormatter,
}) => {
	const sinceDate = new Date(since);
	const [startDate, setStartDate] = useState(sinceDate);
	const tillDate = new Date(till);
	const [scheduleLowerBand, setScheduleLowerBand] = useState(tillDate);
	const { duration, durationAsString, error } = useFormatDurationToString(
		startDate,
		scheduleLowerBand,
	);
	const { mutate } = useUpdateStepInstanceSchedule(stepId);
	const onSave = useCallback(
		(plannedDateTime, plannedDuration) => {
			mutate({ plannedDateTime, plannedDuration });
		},
		[mutate],
	);
	return (
		<Popover>
			<PopoverTrigger className="hover:underline group">
				{!!modified && <ExclamationTriangleIcon className="inline" />}
				{dateFormatter(since)} - {dateFormatter(till)}{" "}
				<Pencil1Icon className="opacity-0 transition-opacity inline group-hover:!opacity-100" />
			</PopoverTrigger>
			<PopoverContent>
				<div className="flex-row items-center flex gap-4">
					<div>
						<Label className="font-bold">
							<FormattedMessage id="PLANNED_START" />
						</Label>
						<TimePickerInput date={startDate} setDate={setStartDate} />
					</div>
					<ArrowRightIcon />
					<div>
						<Label className="font-bold">
							<FormattedMessage id="PLANNED_END" />
						</Label>
						<TimePickerInput
							date={scheduleLowerBand}
							setDate={setScheduleLowerBand}
						/>
					</div>
				</div>
				<div className="flex items-center pt-4">
					{error ? (
						<span className="text-xs text-red-500">
							<FormattedMessage id={error} />
						</span>
					) : (
						<span className="text-xs">
							<ClockIcon className="inline align-sub" /> {durationAsString}
						</span>
					)}
					<Button
						disabled={!!error}
						onClick={() => onSave(startDate, duration)}
						className="ms-auto"
					>
						<FormattedMessage id="SAVE" />
					</Button>
				</div>
			</PopoverContent>
		</Popover>
	);
};
