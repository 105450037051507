import { useRoles, useUpdateAssignments } from "@metronome/api/useAssignments";
import { useGetPartiesFiltersInfinite } from "@metronome/api/useFilters";
import { ComboboxPaginated } from "@metronome/components/ComboBoxPaginated";
import { Button } from "@metronome/components/ui/button";
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from "@metronome/components/ui/select";

import { useCallback, useState } from "react";
import type { MouseEvent } from "react";
import { FormattedMessage, useIntl } from "react-intl";

type Option = {
	label: string;
	value: string;
	icon?: string;
};

export const AssignmentModal: React.FC<{
	contextId: string;
	context: "step-instances" | "process-instances";
	assignments: {
		partyId: string;
		partyName: string;
		roleId: string;
		roleName: string;
	}[];
	onSuccess: () => void;
}> = ({ contextId, context, assignments, onSuccess }) => {
	const intl = useIntl();
	const [search, setSearch] = useState("");
	const [partyId, setPartyId] = useState("");
	const [roleId, setRoleId] = useState("");

	const { mutate: updateAssignment, isPending } = useUpdateAssignments(
		context,
		contextId,
		onSuccess,
	);

	const {
		data: parties,
		fetchNextPage,
		hasNextPage,
	} = useGetPartiesFiltersInfinite(search);

	const { data: roles, isLoading: isRolesLoading } = useRoles(
		context,
		contextId,
	);
	const rolesToSelect = roles?.sort((a, b) => a.name.localeCompare(b.name));

	const partiesToFilter = assignments.map((a) => a.partyId);

	// this one is ugly.
	const partiesToSelect = parties?.pages
		.flatMap((page) => page?.results)
		.filter((party) => !partiesToFilter.includes(party?.id ?? ""))
		.map((party) => {
			return {
				label: party?.name,
				value: party?.id,
				icon: party?.profilePictureUri,
			};
		})
		.filter((party) => party?.value !== undefined) as Option[];

	const onClickSaveAssignments = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			updateAssignment(
				assignments
					.filter((a) => a.roleId?.length && a.partyId?.length)
					.map((a) => ({ partyId: a.partyId, roleId: a.roleId }))
					.concat({ partyId, roleId }),
			);
		},
		[assignments, updateAssignment, partyId, roleId],
	);

	if (!isRolesLoading && !rolesToSelect?.length) {
		return (
			<div className="flex flex-col gap-4">
				<FormattedMessage id="NO_ROLES" />
				<br />
				<FormattedMessage id="CANNOT_ADD_ASSIGNEE" />
			</div>
		);
	}

	return (
		<form key={context + contextId} className="flex flex-col gap-4">
			<section className="flex flex-col gap-2">
				<Select
					key="roles"
					value={roleId}
					onValueChange={(id) => setRoleId(id)}
				>
					<SelectTrigger>
						<SelectValue
							placeholder={intl.formatMessage({
								id: "CHOOSE_ROLE",
							})}
						/>
					</SelectTrigger>
					<SelectContent>
						{rolesToSelect?.map((role) => (
							<SelectItem key={role.id} value={role.id}>
								{role.name}
							</SelectItem>
						))}
					</SelectContent>
				</Select>
				<ComboboxPaginated
					setSearchValue={setSearch}
					value={partyId}
					setValue={setPartyId}
					items={partiesToSelect}
					fetchNextPage={fetchNextPage}
					hasNextPage={hasNextPage}
				/>
			</section>
			<div className="flex flex-row justify-end">
				<Button
					disabled={isPending || !roleId || !partyId}
					onClick={onClickSaveAssignments}
				>
					{isPending
						? intl.formatMessage({ id: "LOADING" })
						: intl.formatMessage({
								id: "REPLAN",
							})}
				</Button>
			</div>
		</form>
	);
};
