import { useStepInstancesFromProcesses } from "@metronome/api/useStepInstance";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { ProgressBar } from "@metronome/components/Progress";
import {
	EXPANDED_SIZE,
	planbyLightTheme,
} from "@metronome/constants/planbyTheme";
import { CustomItem } from "@metronome/features/planby/CustomItem";
import type { IStage } from "@metronome/types/Gate";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import {
	ChannelBox,
	type ChannelItem,
	Epg,
	Layout,
	type Program,
	useEpg,
} from "@nessprim/planby-pro";
import { endOfDay, format } from "date-fns";
import { type FC, useMemo, useState } from "react";
import { FormattedDate, FormattedMessage, useIntl } from "react-intl";
import { Route } from "./onDemand";
import { Link } from "@tanstack/react-router";
import {
	durationInDays,
	getDayWidth,
	getDefaultTimeRange,
	getStartDate,
	globalStyles,
} from "@metronome/utils/planby";
import {
	getTimelinessFromStepInstance,
	getTimelinessFromTimeline,
} from "@metronome/utils/timeliness";
import { getHexColorFromStatusClass } from "@metronome/utils/status";
import type { EResolution } from "@metronome/types/Resolution";
import { TimelinessLozenge } from "@metronome/components/TimelinessTag";
import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import { Button } from "@metronome/components/ui/button";
import { ArrowRightIcon, CalendarIcon } from "@radix-ui/react-icons";
import { useProcessInstances } from "@metronome/api/useProcessInstance";
import { FilterDateRange } from "@metronome/components/FilterDateRange";
import { atEndOfDay, atMidnight } from "@metronome/utils/dateHelpers";
import DebouncedInput from "@metronome/components/DebouncedInput";

type ChannelScheduleProps = {
	schedule: IProcessInstance["schedule"];
};
const ChannelSchedule: FC<ChannelScheduleProps> = ({ schedule }) => {
	return (
		<div className="w-full flex flex-row justify-between text-slate-500">
			<span>
				<FormattedDate value={schedule.lowerTimeBand} />
			</span>
			<span>{"->"}</span>
			<span>
				<FormattedDate value={schedule.upperTimeBand} />
			</span>
		</div>
	);
};

const CustomChannelItem = ({ channel }: ChannelItem) => {
	const { position, title, uuid, progress, schedule, resolution, workspaceId } =
		channel;
	return (
		<ChannelBox
			className="p-1 bg-slate-400 z-50 gap-2 items-start"
			{...position}
		>
			<div className="shadow border rounded flex flex-col w-full p-2 self-start">
				<div className="flex items-center gap-2">
					<TimelinessLozenge timeliness={getTimelinessFromTimeline(schedule)} />
					<ProgressBar
						key={uuid}
						progress={progress ?? 0}
						colorCouple={getHexColorFromStatusClass(
							resolution as EResolution,
							getTimelinessFromTimeline(schedule),
						)}
						className="h-1"
					/>
				</div>
				<Link
					to="/$workspaceId/process/$processId/gates-and-steps"
					params={{ workspaceId, processId: uuid }}
				>
					<div className="text-slate-600 hover:text-primary font-semibold text-lg">
						{title}
					</div>
				</Link>
				<ChannelSchedule schedule={schedule} />
			</div>
		</ChannelBox>
	);
};

type ProcessesTimelineProps = {
	processes: IProcessInstance[];
	stages: Array<IStage[]>;
	processIds: string[];
	workspaceId: string;
};

type FilterProcessTimelineProps = {
	dateStartRange: [string, string] | undefined;
	setDateStartRange: (val: [string, string] | undefined) => void;
	dateEndRange: [string, string] | undefined;
	setDateEndRange: (val: [string, string] | undefined) => void;
	isLoading: boolean;
	filter: string | undefined;
	setFilter: (value: string) => void;
};

const FilterProcessTimeline: React.FC<FilterProcessTimelineProps> = ({
	dateStartRange,
	setDateStartRange,
	dateEndRange,
	setDateEndRange,
	filter,
	setFilter,
	isLoading,
}) => {
	const intl = useIntl();
	return (
		<div className="px-2 pt-2 flex justify-between items-center gap-2">
			<div className="flex items-center gap-2">
				<Dialog>
					<DialogTrigger className="m-1" asChild>
						<Button className="relative" variant="outline">
							<CalendarIcon className="me-1" />
							<FormattedMessage id="PLANNED_START" />
							{!!dateStartRange?.length && (
								<span className="absolute -top-2 -right-2 w-4 h-4 text-center text-xs shadow rounded-full bg-primary text-white">
									{"1"}
								</span>
							)}
						</Button>
					</DialogTrigger>
					<DialogContent>
						<DialogHeader>
							<DialogTitle>Filter by date range</DialogTitle>
						</DialogHeader>
						<FilterDateRange
							value={dateStartRange}
							setValue={setDateStartRange}
						/>
					</DialogContent>
				</Dialog>
				<ArrowRightIcon />
				<Dialog>
					<DialogTrigger className="m-1" asChild>
						<Button className="relative" variant="outline">
							<CalendarIcon className="me-1" />
							<FormattedMessage id="PLANNED_END" />
							{!!dateEndRange?.length && (
								<span className="absolute -top-2 -right-2 w-4 h-4 text-center text-xs shadow rounded-full bg-primary text-white">
									{"1"}
								</span>
							)}
						</Button>
					</DialogTrigger>
					<DialogContent>
						<DialogHeader>
							<DialogTitle>Filter by date range</DialogTitle>
						</DialogHeader>
						<FilterDateRange value={dateEndRange} setValue={setDateEndRange} />
					</DialogContent>
				</Dialog>
			</div>
			<DebouncedInput
				isLoading={isLoading}
				value={filter ?? ""}
				onChange={(value) => {
					setFilter(String(value));
				}}
				placeholder={intl.formatMessage({ id: "SEARCH_ALL_COLUMNS" })}
			/>
		</div>
	);
};

export const ProcessTimelineLoader: React.FC<{ processStreamId: string }> = ({
	processStreamId,
}) => {
	const [dateStartRange, setDateStartRange] = useState(undefined);
	const [dateEndRange, setDateEndRange] = useState(undefined);
	const [filter, setFilter] = useState(undefined);
	const { workspaceId } = Route.useParams();
	const { data: processes } = useProcessInstances({
		processStreamId: processStreamId,
		states: ["active"],
		plannedStartTimes: ["past" as const, "today" as const],
		page: 1,
		pageSize: 20,
		search: filter,
		plannedStartFrom: dateStartRange?.length
			? atMidnight(dateStartRange[0])?.toJSON()
			: undefined,
		plannedStartTo:
			dateStartRange?.length > 1
				? atEndOfDay(dateStartRange[1])?.toJSON()
				: undefined,
		plannedEndFrom: dateEndRange?.length
			? atMidnight(dateEndRange[0])?.toJSON()
			: undefined,
		plannedEndTo:
			dateEndRange?.length > 1
				? atEndOfDay(dateEndRange[1])?.toJSON()
				: undefined,
	});
	const processIds = processes?.results?.map((p) => p.id);
	const { data, pending } = useStepInstancesFromProcesses(processIds);
	return (
		<div className="bg-white">
			<FilterProcessTimeline
				dateStartRange={dateStartRange}
				setDateStartRange={setDateStartRange}
				dateEndRange={dateEndRange}
				setDateEndRange={setDateEndRange}
				filter={filter}
				setFilter={setFilter}
				isLoading={pending}
			/>

			{pending && !processes?.results?.length && <LoadingMetronome />}
			{!pending && !!processes?.results?.length && (
				<ProcessesTimeline
					processes={processes.results}
					stages={data}
					processIds={processIds}
					workspaceId={workspaceId}
				/>
			)}
		</div>
	);
};

export const ProcessesTimeline: React.FC<ProcessesTimelineProps> = ({
	processes,
	stages,
	processIds,
	workspaceId,
}) => {
	const plannedStart = processes[0].schedule.lowerTimeBand;
	const startDate = getStartDate(plannedStart);

	const plannedEnd = processes[processes.length - 1].schedule.upperTimeBand;
	const endDate = endOfDay(plannedEnd);
	const endDateFormatted = format(endDate, "yyyy-MM-dd'T'HH:mm:ss");
	const channels = useMemo(
		() =>
			processes.map((p) => ({
				logo: "",
				uuid: p.id,
				title: p.name,
				progress: p.progress,
				schedule: p.schedule,
				resolution: p.resolution,
				workspaceId: workspaceId,
			})),
		[processes, workspaceId],
	);
	const epg: Program[] = useMemo(
		() =>
			stages.flatMap((g, i) => {
				const channelUuid = processIds[i];
				return g.flatMap((step) =>
					step.steps.flatMap((instance) =>
						instance.stepInstances?.map((i) => {
							if (instance.stepInstances.length === 0) return;
							const since = format(
								i.schedule.scheduleLowerBand,

								"yyyy-MM-dd'T'HH:mm:ss",
							);
							const till = format(
								i.schedule.scheduleUpperBand,
								"yyyy-MM-dd'T'HH:mm:ss",
							);
							const nodeName = i.businessDimension.name;
							const timeliness = getTimelinessFromStepInstance(
								i.schedule,
								undefined,
								i.type,
							);

							return {
								workspaceId,
								channelUuid,
								id: i.id,
								type: i.type,
								timeliness,
								title:
									instance.stepInstances.length > 1
										? `${nodeName} - ${instance.name}`
										: instance.name,
								since,
								till,
							};
						}),
					),
				);
			}),
		[processIds, stages, workspaceId],
	);
	const filteredEpg = useMemo(() => epg.filter((e) => !!e), [epg]);

	// these could be consolidated into a single helper
	const defaultRange = getDefaultTimeRange(plannedStart, plannedEnd);
	const numberOfDays = durationInDays(plannedStart, plannedEnd);
	const dayWidth = getDayWidth(defaultRange, numberOfDays);

	const { getEpgProps, getLayoutProps } = useEpg({
		epg: filteredEpg,
		channels,
		startDate,
		endDate: endDateFormatted,
		mode: { type: defaultRange, style: "modern" },
		sidebarWidth: 220,
		dayWidth,
		itemHeight: EXPANDED_SIZE,
		isSidebar: true,
		isTimeline: true,
		isLine: true,
		isCurrentTime: true,
		theme: planbyLightTheme,
		dnd: {
			enabled: false,
			mode: "row",
		},
		overlap: {
			enabled: true,
			// mode: "layer",
			// layerOverlapLevel: 0.6,
			mode: "stack",
		},
		globalStyles,
	});
	if (filteredEpg.length === 0)
		return <span>We could not display any items on the timeline.</span>;
	return (
		<Epg {...getEpgProps()}>
			<Layout
				{...getLayoutProps()}
				renderChannel={({ channel, ...rest }) => (
					<CustomChannelItem key={channel.uuid} channel={channel} {...rest} />
				)}
				renderProgram={({ program, ...rest }) => (
					<CustomItem key={program.data.id} program={program} {...rest} />
				)}
			/>
		</Epg>
	);
};
